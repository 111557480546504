<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark"></b-spinner>
          <b-spinner type="grow" variant="dark"></b-spinner>
          <b-spinner small type="grow" variant="dark"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <b-button variant="success" v-b-modal.new-modal>ثبت تخفیف</b-button>
    <b-card title="محصولات ویژه" class="mt-3">
      <b-button v-b-toggle.filterBox variant="primary" class="mb-3 position-absolute" style="top:20px; left: 20px;"
        size="sm"><i class="fa fa-bars"></i></b-button>
      <b-collapse id="filterBox" class="my-2">
        <b-card>
          <form @submit.prevent="searchItems()" id="search-item">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="title">عنوان</label>
                  <b-form-input id="title" v-model="searchParams.title"></b-form-input>
                </div>
              </div>
              
              <div class="col-md-3">
                <b-form-group label="تاریخ شروع" label-for="start_date">
                  <date-picker type="datetime" v-model="searchParams.start_date" format="jYYYY-jMM-jDD HH:mm" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="تاریخ پایان" label-for="end_date">
                  <date-picker type="datetime" v-model="searchParams.end_date" format="jYYYY-jMM-jDD HH:mm" />
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="وضعیت" label-for="status">
                    <b-form-select id="status" v-model="searchParams.status">
                        <b-form-select-option value="1">فعال</b-form-select-option>
                        <b-form-select-option value="0">غیرفعال</b-form-select-option>
                        <b-form-select-option value="">همه</b-form-select-option>
                    </b-form-select>
                </b-form-group>
            </div>
            </div>
            <button class="btn btn-primary btn-sm float-left" type="submit" :disabled="disabled">جست و
              جو</button>
          </form>
        </b-card>
      </b-collapse>
      <b-table responsive striped hover v-if="items.data" :fields="table_fields" :items="items.data.data">

        <template v-slot:cell(created_at)="data">
          {{ data.item.created_at | persianDate }}
        </template>

        <template v-slot:cell(start_date)="data">
          <span v-if="data.item.start_date"> {{data.item.start_date | persianDate}}</span>
        </template>

        <template v-slot:cell(end_date)="data">
          <span v-if="data.item.end_date">{{data.item.end_date | persianDate}}</span>
        </template>

        <template v-slot:cell(status)="data">
          <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
          <span class="badge badge-light" v-else>غیرفعال</span>
        </template>

        <template v-slot:cell(add_products)="data">
          <button class="btn btn-warning" @click="addDiscountForProduct(data.index)"><i class="fa fa-cog"></i></button>
        </template>
        <template v-slot:cell(edit)="data">
          <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
        </template>
        <template v-slot:cell(delete)="data">
          <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>

    <pagination :limit="2" v-if="items.data && !search" :data="items.data" @pagination-change-page="loadItems">
    </pagination>
    <pagination :limit="2" v-else-if="items.data && search" :data="items.data" @pagination-change-page="searchItems">
    </pagination>


    <b-modal id="editDiscountVariety" title="ویرایش تخفیف تنوع" v-if="editDiscountVariety.pivot" hide-footer>
      <form @submit.prevent="varietyEdited" id="edit-variety">
        <b-form-group>
          <label for="percent">درصد : <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
          <b-form-input id="percent" name="percent" v-model="editDiscountVariety.pivot.percent" data-required
            :disabled="disabled"></b-form-input>
        </b-form-group>
        <div class="" v-if="editDiscountVariety.product.unit == 'number'">
          <div class="form-group">
            <div class="d-flex justify-content-between">
              <span v-if="editDiscountVariety.product.unit == 'number'" class="float-right">تعداد : <i
                  class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></span>
              <div>
                <label for="in_storage"> موجود در انبار : {{ editDiscountVariety.in_storage }}</label>
              </div>
            </div>

            <input class="form-control" id="number" name="number" :disabled="disabled"
              v-model="editDiscountVariety.pivot.quantity">
          </div>
        </div>
        <div class="" v-if="editDiscountVariety.product.unit == 'meter'">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="meter"> متر</label>
              <input class="form-control" id="meter" name="meter">
            </div>
            <div class="form-group col-md-6">
              <label for="cm"> سانتی متر</label>
              <input class="form-control" id="cm" name="cm">
            </div>
          </div>
        </div>
        <div class="" v-if="editDiscountVariety.product.unit == 'kg'">
          <div class="row">
            <div class="form-group col-md-6">
              <label for="meter ">کیلو</label>
              <input class="form-control" id="kilo" name="kilo">
            </div>
            <div class="form-group col-md-6">
              <label for="cm">گرم</label>
              <input class="form-control" id="gram" name="gram">
            </div>
          </div>
        </div>
        <input type="hidden" name="_method" value="put">
        <input type="hidden" name="variety_id" v-model="editDiscountVariety.id">
        <input type="hidden" name="discount_id" v-model="selectedDiscount.id">
        <button class="btn btn-primary" type="submit" :disabled="disabled">ویرایش</button>
      </form>
    </b-modal>

    <b-modal id="addDiscountForProduct" title="اضافه کردن محصول" hide-footer size="xl">
      <div class="row">
        <div class="col-6">
          <form @submit.prevent="addToVarieties" id="add-variety">
            <div class="form-group">
              <label for="product">انتخاب محصول: <i class="fas fa-asterisk text-danger"
                  style="font-size: 0.5rem;"></i></label>
              <multiselect :options="products" label="title" v-model="newVarietyDiscount.product" id="product"
                @input="loadVarieties" track-by="id" :searchable="true" :close-on-select="true" :show-labels="false"
                :disabled="disabled">
              </multiselect>
            </div>
            <div v-if="! newVarietyDiscount.loading && varieties.length > 0">
              <div class="form-group">
                <label for="variety">انتخاب تنوع: <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <multiselect class="form-group" :options="varieties" label="fullTitle"
                  v-model="newVarietyDiscount.variety" id="variety" track-by="id" :searchable="true"
                  :close-on-select="true" :show-labels="false" :disabled="disabled">
                </multiselect>
                <input type="hidden" name="variety_id" v-model="newVarietyDiscount.variety.id"
                  v-if="newVarietyDiscount.variety.id" :disabled="disabled">

              </div>
              <div class="form-group">
                <label for="percent">درصد تخفیف: <i class="fas fa-asterisk text-danger"
                    style="font-size: 0.5rem;"></i></label>
                <b-form-input id="percent" name="percent" v-model="newVarietyDiscount.percent" :disabled="disabled">
                </b-form-input>
              </div>
              <div>
                <label for="quantity" class="row px-0">
                  <div class="col-6">
                    <span v-if="newVarietyDiscount.product.unit == 'number'" class="float-right">تعداد : <i
                        class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></span>
                    <span v-else-if="newVarietyDiscount.product.unit == 'meter'" class="float-right">متراژ : <i
                        class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></span>
                    <span v-else-if="newVarietyDiscount.product.unit == 'kg'" class="float-right">وزن : <i
                        class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></span>
                  </div>
                  <div class="col-6">
                    <span v-if="! newVarietyDiscount.variety.in_storage == ''" class="float-left">موجود در انبار :
                      {{ newVarietyDiscount.variety.in_storage }}</span>
                  </div>
                </label>
                <div class="" v-if="newVarietyDiscount.product.unit == 'number'">
                  <div class="form-group">
                    <!-- <label for="in_storage"> موجود در انبار</label> -->
                    <input class="form-control" id="number" name="number" :disabled="disabled">
                  </div>
                </div>
                <div class="" v-if="newVarietyDiscount.product.unit == 'meter'">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="meter"> متر</label>
                      <input class="form-control" id="meter" name="meter" v-model="meter">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="cm"> سانتی متر</label>
                      <input class="form-control" id="cm" name="cm" v-model="cm">
                    </div>
                  </div>
                </div>
                <div class="" v-if="newVarietyDiscount.product.unit == 'kg'">
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="meter ">کیلو</label>
                      <input class="form-control" id="kilo" name="kilo" v-model="kilo">
                    </div>
                    <div class="form-group col-md-6">
                      <label for="cm">گرم</label>
                      <input class="form-control" id="gram" name="gram" v-model="gram">
                    </div>
                  </div>
                </div>
              </div>
              <input type="hidden" name="discount_id" v-model="selectedDiscount.id">
              <button class="btn btn-success" type="submit" :disabled="disabled">ثبت تخفیف</button>
            </div>
          </form>
        </div>
        <div class="col-6">

          <b-table responsive striped hover :fields="varieties_table_fields" :items="selectedDiscount.varieties">
            <template v-slot:cell(percent)="data">
              {{ data.item.pivot.percent }}
            </template>
            <template v-slot:cell(quantity)="data">
              {{ data.item.pivot.quantity }}
            </template>
            <template v-slot:cell(edit)="data">
              <button class="btn btn-primary btn-sm" @click="editVariety(data.index)">
                <i class="fa fa-edit"></i>
              </button>
            </template>
            <template v-slot:cell(delete)="data">
              <button class="btn btn-danger btn-sm" @click="deleteVariety(data.index)">
                <i class="fa fa-close"></i>
              </button>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>

    <b-modal id="new-modal" title="ثبت تخفیف" hide-footer>
      <div>
        <form @submit.prevent="newItem" id="new-item">

          <b-form-group>
            <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="title" name="title" data-required :disabled="disabled"></b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="start_date"> تاریخ شروع <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <date-picker type="datetime" name="start_date"  format="jYYYY-jMM-jDD HH:mm" />
          </b-form-group>
          <b-form-group>
            <label for="end_date"> تاریخ پایان <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <date-picker type="datetime" name="end_date" format="jYYYY-jMM-jDD HH:mm" />
          </b-form-group>
          <b-form-group label="توضیحات" label-for="description">
            <b-form-input id="description" name="description" :disabled="disabled"></b-form-input>
          </b-form-group>

          <b-form-group label="بارگذاری تصویر" label-for="image">
            <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
          </b-form-group>
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status"
                :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <div class="clearfix">
            <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <b-modal id="edit-modal" title="ویرایش تخفیف" hide-footer>
      <div v-if="edit.id">
        <form @submit.prevent="itemEdited" id="edit-item">
          <b-form-group>
            <label for="title"> عنوان <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
            <b-form-input id="title" name="title" v-model="edit.title" data-required :disabled="disabled">
            </b-form-input>
          </b-form-group>
          <b-form-group>
            <label for="start_date"> تاریخ شروع <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <date-picker type="datetime" name="start_date" format="jYYYY-jMM-jDD HH:mm" input-format="YYYY-MM-DD HH:mm" :value="edit.start_date" />
          </b-form-group>
          <b-form-group>
            <label for="end_date"> تاریخ پایان <i class="fas fa-asterisk text-danger"
                style="font-size: 0.5rem;"></i></label>
            <date-picker type="datetime" name="end_date" format="jYYYY-jMM-jDD HH:mm" input-format="YYYY-MM-DD HH:mm" :value="edit.end_date" />
          </b-form-group>
          <b-form-group label="توضیحات" label-for="description">
            <b-form-input id="description" name="description" v-model="edit.description" :disabled="disabled">
            </b-form-input>
          </b-form-group>

          <b-form-group label="بارگذاری تصویر" label-for="image">
            <b-form-file id="image" name="image" :disabled="disabled"></b-form-file>
          </b-form-group>
          <div class="form-group">
            <label class="custom-switch">
              <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status"
                :disabled="disabled">
              <span class="custom-switch-indicator"></span>
              <span class="custom-switch-description">وضعیت</span>
            </label>
          </div>
          <div class="clearfix">
            <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
            <b-button class="float-left" variant="white" type="button"
              @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
          </div>
          <input type="hidden" value="put" name="_method">
        </form>
      </div>
    </b-modal>

  </div>
</template>

<script>
  import mixins from './mixins/mixins'

  export default {
    mixins: [mixins],
    data() {
      return {
        url: '/api/admin/discounts',
        title: 'محصولات ویژه',
        newVarietyDiscount: {
          product: {},
          variety: {},
          loading: false,
          percent: '',
          quantity: ''
        },
        editDiscountVariety: {},
        products: [],
        varieties: [],
        selectedDiscount: {},

        varieties_table_fields: [{
            key: 'fullTitle',
            label: 'عنوان'
          },
          {
            key: 'percent',
            label: 'تخفیف (درصد)'
          },
          {
            key: 'quantity',
            label: 'تعداد'
          },
          {
            key: 'edit',
            label: 'ویرایش'
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ],
        table_fields: [{
            key: 'id',
            label: 'شناسه '
          },
          {
            key: 'title',
            label: 'عنوان'
          },
          {
            key: 'start_date',
            label: 'تاریخ شروع'
          },
          {
            key: 'end_date',
            label: 'تاریخ پایان'
          },
          {
            key: 'add_products',
            label: 'محصولات'
          },
          {
            key: 'status',
            label: 'وضعیت'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'edit',
            label: 'ویرایش '
          },
          {
            key: 'delete',
            label: 'حذف'
          }
        ],
        selected_status: '1',
        selected_filter_status: '',
      }
    },
    computed: {
      editStartDate() {
        return window.moment(this.edit.start_date).format('jYYYY-jMM-jDD HH:MM')
      },
      editEndDate() {
        return window.moment(this.edit.end_date).format('jYYYY-jMM-jDD HH:MM')
      }
    },
    methods: {
      addDiscountForProduct(index) {
        const item = window.clone(this.items.data.data[index])
        item.index = index
        this.selectedDiscount = item

        this.$axios.get(this.$root.baseUrl + `/api/admin/list/variety/discount/${item.id}`)
          .then(response => {
            this.$root.$set(this.selectedDiscount, 'varieties', response.data.data.varieties)
          })
        this.$root.$emit('bv::show::modal', 'addDiscountForProduct')
      },
      loadVarieties(product) {
        this.newVarietyDiscount.loading = true
        this.varieties = []
        this.$axios.get(this.$root.baseUrl + '/api/admin/list/varieties/' + product.id)
          .then(response => {
            this.newVarietyDiscount.loading = false
            if (response.data.data.varieties.length > 0) {
              this.varieties = response.data.data.varieties
            } else {
              window.swal('برای محصول انتخابی تنوعی وجود ندارد')
            }
          })
      },
      editVariety(index) {
        const item = window.clone(this.selectedDiscount.varieties[index])
        this.editDiscountVariety = item

        setTimeout(() => {
          this.$root.$emit('bv::show::modal', 'editDiscountVariety')
        }, 500)
      },
      deleteVariety(index) {
        window.swal({
            title: "آیتم حذف شود؟",
            text: "این عملیات غیر قابل بازگشت خواهد بود",
            icon: "warning",

            showCancelButton: true,
            showConfirmButton: true,
            dangerMode: true,
            buttons: ["خیر", "بله حذف کن"]
          })
          .then((willDelete) => {
            if (willDelete) {
              const item = this.selectedDiscount.varieties[index]
              const url = `/api/admin/delete/variety/${item.id}/${this.selectedDiscount.id}`
              this.$axios.delete(this.$root.baseUrl + url)
                .then((response) => {
                  this.$root.$delete(this.selectedDiscount.varieties, index)
                  window.swal({
                    icon: "success",
                    showConfirmButton: true,
                    text: response.data.message,
                    button: "باشه",
                  })
                })
                .catch(error => {
                  window.swal({
                    title: 'خطا',
                    showConfirmButton: true,
                    text: error.response.data.message,
                    button: "باشه",


                  })
                })
            }
          })
          .catch(() => {

          })
      },
      addToVarieties() {
        this.disabled = true
        let form = document.getElementById('add-variety')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + '/api/admin/add/variety/discount', formData)
          .then(response => {
            this.$root.success_notification(response.data.message)
            this.addDiscountForProduct(this.selectedDiscount.index)
            this.newVarietyDiscount.product = {}
            this.newVarietyDiscount.variety = {}
            this.newVarietyDiscount.loading = false
            this.newVarietyDiscount.percent = ''
            this.newVarietyDiscount.quantity = ''
            // this.$root.$emit('bv::hide::modal', 'addDiscountForProduct')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      },
      varietyEdited() {
        this.disabled = true
        let form = document.getElementById('edit-variety')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + '/api/admin/update/variety/discount', formData)
          .then(response => {
            this.$root.$set(this.selectedDiscount, 'varieties', response.data.data.discount.varieties)
            this.$root.$emit('bv::hide::modal', 'editDiscountVariety')
          })
          .catch(error => {
            this.$root.error_notification(error)
          })
          .finally(() => {
            this.disabled = false
          })
      }
    },
    created() {
      this.loadItems()
      this.$axios.get(this.$root.baseUrl + '/api/admin/list/products')
        .then(response => {
          this.products = response.data.data
        })
        this.searchParams = {
        title: '',
        status: '',
        start_date: '',
        end_date: '',
      }
      // window.addToVarieties = (response) => {
      //   if (response.success) {
      //     this.addDiscountForProduct(this.selectedDiscount.index)
      //     this.newVarietyDiscount.product = {}
      //     this.newVarietyDiscount.variety = {}
      //     this.newVarietyDiscount.loading = false
      //     this.newVarietyDiscount.percent = ''
      //     this.newVarietyDiscount.quantity = ''
      //   }
      // }


    }
  }
</script>